import axios, { AxiosResponse } from "axios";
import { getCookie } from "../store/Cookie";
import { logoutWithNavigateHome } from "../utils/help";

const apiService = axios.create({
    // NOTE: REACT_APP_API_BASE_URL is injected by AWS Amplify
    baseURL: process.env.REACT_APP_API_BASE_URL ?? "", // proxy 사용할때 설정
    headers: {
        "content-type": "application/json",
    },
});

async function httpGet(endpoint: string) {
    apiService.defaults.headers.common.Authorization = _getAuthorization();
    const response = await apiService.get(endpoint);
    return handleResponse(response);
}

async function httpPost(endpoint: string, data: any) {
    apiService.defaults.headers.common.Authorization = _getAuthorization();
    const response = await apiService.post(endpoint, data);
    return handleResponse(response);
}

async function httpPut(endpoint: string, data: any) {
    apiService.defaults.headers.common.Authorization = _getAuthorization();
    const response = await apiService.put(endpoint, data);
    return handleResponse(response);
}

async function httpDelete(endpoint: string) {
    apiService.defaults.headers.common.Authorization = _getAuthorization();
    const response = await apiService.delete(endpoint);
    return handleResponse(response);
}

const _getAuthorization = () => {
    return "Bearer " + getCookie("accessToken");
};

const handleResponse = (response: AxiosResponse<any, any>) => {
    if (response.status === 200) {
        return response.data;
    } else {
        alert(response.statusText);
        throw Error(response.statusText);
    }
};

// AxiosError는 catch에서 발생함.
const Unauthrized = (error: any) => {
    if (error.response.status === 401) {
        // unauthrized
        alert(error.message);
        logoutWithNavigateHome();
    }
};

const PostApi = async (url: string, data: any) => {
    let response;
    try {
        response = await httpPost(url, data);
    } catch (error: any) {
        console.warn(error);
        // Unauthrized(error);
        throw error;
    }
    return response;
};

const PutApi = async (url: string, data: any) => {
    let response;
    try {
        response = await httpPut(url, data);
    } catch (error: any) {
        console.warn(error);
        // Unauthrized(error);
    }
    return response;
};

const GetApi = async (url: string, param = "") => {
    let response;
    try {
        response = await httpGet(url + param);
        if (response.statusCode === 400) {
            alert(`오류 : ${response.optionalMessage}. (code=${response.optionalCode})`);
        }
    } catch (error: any) {
        console.warn(error);
        Unauthrized(error);
    }
    return response;
};

export { GetApi, httpDelete, httpGet, httpPost, httpPut, PostApi, PutApi };
