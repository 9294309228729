import moment from "moment";
import { GetApi } from "../api/apiService";
import { removeCookie } from "../store/Cookie";
import { ACCESS_TOKEN_COOKIE_KEY, USER_INFO_COOKIE_KEY } from "../constant";

// 현재는 사용되는 값이 같아서 따로 분리 안함
enum eBagStatusIDs {
    ORDER = 1100,
    DELIVERY_PREPARE = 1300,
    DELIVERY_ING = 1400,
    DELIVERY_FINISH = 1500,

    ORDER_CANCEL = 1900,

    COLLECTION_ALL = 0,
    COLLECTION_REQ = 2100,
    COLLECTION_PREPARE = 2300,
    COLLECTION_CHECK = 2400,
    COLLECTION_RESERV = 2430,
    COLLECTION_FINISH = 2500,

    TAGGING = 3100,
    TAGGING_FINISH = 3700,

    SELL_INFO_REQ = 4100,
    SELL_INFO_CHECK = 4900,
}

// https://docs.google.com/spreadsheets/d/1AG2YcCh5tJaNIr5ojMtqZ0lt7yxhLI6JxueVLZ5VUas/edit?lfhs=2#gid=1119367053
enum eItemStatusIDs {
    NOT_FOR_SELL = 129000, // 판매불가
    REGISTERED = 170000, // 등록완료
    PICTURE_COMPLETED = 180000, // 촬영완료
    TAGGING_COMPLETED = 190000, // 태깅완료
    INSPECTED = 199000, // 검수완료
    SELL_INFO_REQUESTED = 310000, // 판매정보요청
    SELL_INFO_CHECKED = 390000, // 판매정보확인
    DP_EXCEPTION1 = 411200, // 판매불가(검수 서비스에서 컨디션 검수 단계에서 판매불가 판정을 받아서 방출 서비스로 이관되면서 부여 받는 코드)
    DP_EXCEPTION2 = 413000, // 판매불가(가격 책정 서비스에서 가품 인증 실패 등의 이유로 판매불가 처리된 상품들)
    DP_FINISHED = 415200, // 전시종료(검수 상태 체크 단계에서 판매불가 판정)
    EGRESS_WAIT = 421000, // Egress 대기 상태
    DP_STOPPED = 419000, // 전시중지

    DONATION_INFO_REQ = 431000, // 기부정보요청
    DONATION_INFO_CHECK = 432000, // 기부정보확인
    DONATION_DELIVER_PREP = 433000, // 기부배송준비
    DONATION_PACKED = 433500, // 기부포장완료
    DONATION_DELIVERING = 434000, // 기부배송중
    DONATION_DELIVERED = 435000, // 기부처리완료

    RETURNING_REQ = 441000, // 회수정보요청
    RETURNING_CHECK = 442000, // 회수정보확인
    RETURNING_DELIVER_PREP = 443000, // 회수배송준비
    RETURNING_DELIVER_START = 444000, // 회수배송시작
    RETURNING_DELIVERED = 445000, // 회수배송완료

    INBOUND_STANDBY = 451000, // 귀속상품 대기 상태

    _3PL_REQUEST = 521000,
    _3PL_PLACED = 523000,
    _3PL_PACKED = 523500,
    _3PL_SHIPPED = 524000,
    _3PL_DELIVERED = 525000,
    _3PL_MISS_CHECKING = 526000,
    _3PL_MISS_CONFIRM = 527000,
    _3PL_MISS_SETTLED = 529000,

    SELL_ING = 531000, // 판매중

    // 아래 상태는 모두 판매상품조회/수정에서는 품절에 해당됨.
    ORDER_CHECKED = 720000, // 주문완료
    ORDER_EXPORT_REQ = 730000, // 출고요청
    DELIVERY_START = 740000, // 배송시작
    DELIVERY_FIN = 750000, // 배송완료
    PURCHASE_CONFIRM = 790000, // 구매확정
    REFUND_REQ = 910000, // 반품요청
    REFUND_CHECKED = 930000, // 반품요청확인
    REFUND_REG = 940000, // 반품수거접수완료
    REFUND_COLLECTED = 950000, // 반품수거완료
    REFUND_FIN = 990000, // 반품처리완료

    SOLD_OUT = 540000, // 품절
    SELL_STOP = 550000, // 판매중지
    SELL_DP_FIN = 591000, // 전시종료
    SELL_EXPORT_REQ = 592000, // 반출요청
    SELL_CANCEL = 770000, // 주문취소
}

// TODO(Ginny): should rename the function to include all statuses
export const isNfsItem = (statusID: number) => {
    return (
        statusID === eItemStatusIDs.DP_EXCEPTION1 ||
        statusID === eItemStatusIDs.DP_EXCEPTION2 ||
        statusID === eItemStatusIDs.NOT_FOR_SELL ||
        statusID === eItemStatusIDs.DP_FINISHED
    );
};

export enum eEgressResonType {
    NOT_FOR_SELL1 = 129000,
    NOT_FOR_SELL2 = 411200,
    NOT_FOR_SELL3 = 413000,
    NOT_FOR_SELL4 = 419000,
    NOT_FOR_SELL5 = 415500,
    NOT_FOR_SELL6 = 415900,
    DP_STOPPED1 = 591000,
    DP_STOPPED2 = 415900,
}

enum ePageUpdateType {
    ITEMS = 0x0001,
    STATUS = 0x0002,
    MESSAGE = 0x004,
}

export interface UIStatus {
    id: number;
    src: string;
    returningID?: number;
    title: string;
    count: number;
}
export interface StorageExportItemProps {
    id: number;
    storageImportedAt: string;
    closetID: number;
    storageID: string;
    scanned: boolean;
}
export interface StorageExportListProps {
    [key: string]: StorageExportItemProps[];
}

export const KEY_ENTER = 13;
export const NFS = 900; // not for sell
export const MINEIS = "마인이스 주식회사";
export const MINEIS_PHONE = "01036522694";
export const OPS_PHONE = "031-510-4334";
export const MINEIS_ADDR = "경기도 남양주시 오남읍 오남리 846-15, 대한일코퍼레이션 3층 차란";
export const BRAND = "차란";
export const OFFICE_TELL = "02-6013-2202";
export const POSTAL = "12048";

const UpdateStatus = (items: any, status: UIStatus[]) => {
    if (!items) return status;

    const currentStatus: UIStatus[] = [];
    if (status) {
        status.map((s) => {
            s.count = items.filter((item: { statusID: number }) => item.statusID === s.id).length;
            currentStatus.push(s);
        });
        currentStatus[0].count = items.length;
    }
    return currentStatus;
};

const UpdateStatusCount = async (
    apiUrl: string,
    status: UIStatus[],
    setStatus: any,
    isReturning?: boolean
) => {
    if (!apiUrl || apiUrl.length < 1 || !status) return status;

    const currentStatus = [...status];
    await GetApi(apiUrl)
        .then((response) => {
            const counts = response.counts
                ? response.counts
                : response.itemCounts
                ? response.itemCounts
                : null;
            if (!counts) return;

            let total = 0;
            currentStatus.map((ui) => {
                const temp = isReturning
                    ? counts.filter((info: { statusID: number }) => ui.returningID === info.statusID)
                    : counts.filter((info: { statusID: number }) => ui.id === info.statusID);
                if (temp[0]) {
                    ui.count = temp[0].count;
                    total += ui.count;
                }
            });
            currentStatus[0].count = total;
            setStatus(currentStatus);
        })
        .catch((error) => {
            console.warn(error);
            alert("개수 정보를 가져오는 도중 오류가 발생했습니다.");
        });
};

const ONEDAY_SEC = 24 * 60 * 60 * 1000 - 1000;

const GetLocalDate = (dateStr: string) => {
    if (!dateStr || dateStr.length < 1) return "";

    let d;
    if (dateStr.includes("+")) {
        // +00:00 제거, Date to String 변환시 오류 때문
        d = new Date(dateStr.slice(0, dateStr.length - 6));
    } else {
        d = new Date(dateStr);
    }
    return new Date(d.getTime() - d.getTimezoneOffset() * 60000).toISOString();
};

// YYYY-MM-DDThh:mm:ss.mmmmmmZ+09:00 => YYYY.MM.DD hh:mm:ss
const GetFormattedDate = (dateStr: string) => {
    if (!dateStr) return "-";
    const date = dateStr;
    return date && date.length
        ? date
              .replace(/-/g, ".")
              .replace("T", " ")
              .slice(0, date.length - 7)
        : "-";
};

// YYYY/MM/DD
const GetFormattedDateOnly = (dateStr: string) => {
    if (!dateStr) return "-";
    const date = dateStr.split("T")[0];
    return date && date.length ? date.replaceAll("-", "/") : "-";
};

const GetParamStartEnd = (start: any, end: any, startParam: string, endParam: string): string => {
    if (!end || !start) return "";
    const diff = end.getTime() - start.getTime();
    if (diff < ONEDAY_SEC) {
        return "";
    }

    let params: string;
    const startDate = moment(new Date(start.getTime())).format("YYYY-MM-DD") + "T00:00:00Z+09:00";
    const endDate = moment(new Date(end.getTime())).format("YYYY-MM-DD") + "T23:59:59Z+09:00";

    params = startParam + "=" + startDate;
    params += endParam + "=" + endDate;
    return params;
};

// YYYY-MM-DDThh:mm:ss.mmmmmmZ+09:00 => YYYYMMDDThhmmss
const GetParamDate = (dateStr: string) => {
    if (!dateStr) return;
    const date = dateStr;
    return date && date.length
        ? date
              .replace(/-/g, "")
              .replace(":", "")
              .slice(0, date.length - 7)
        : "-";
};

const GetParamID = (kind: string, ids: string) => {
    if (kind.length <= 0 || ids.length <= 0) {
        return "";
    }

    // string to array
    ids = ids.replace(/\n/g, ",").replace(/ /g, "").replace(/,,/g, ",");
    if (ids.slice(-1) === ",")
        // 마지막 따옴표 제거
        ids = ids.slice(0, -1);
    const array: string[] = ids.split(",");

    let params = "?";
    array.map((id) => {
        params += kind + "[]=" + id + "&";
    });
    return params;
};

const GetParamBrand = (kind: string, ids: string, meta: any) => {
    if (kind.length <= 0 || ids.length <= 0) {
        return "";
    }

    let array: string[] = [];
    if (ids.includes("\n")) {
        if (ids.slice(-1) === "\n") ids = ids.slice(0, -1);
        array = ids.split("\n");
    } else if (ids.includes(",")) {
        if (ids.slice(-1) === ",")
            // 마지막 따옴표 제거
            ids = ids.slice(0, -1);
        array = ids.split(",");
    } else array.push(ids);

    let params = "";
    array.map((id) => {
        if (id) params += "&" + kind + "[]=" + GetMetaID(meta, id);
    });

    return params;
};

const GetParamCategory = (category: string[]) => {
    if (category.length === 0) {
        return "";
    }

    let param = "";
    for (let i = 0; i < category.length; i++) {
        if (i == 0) {
            if (category[i]) param += "mainCatIDs[]=" + category[i];
        }
        if (i == 1) {
            if (category[i]) param += "&subCatIDs[]=" + category[i];
        }
        if (i == 2) {
            if (category[i]) param += "&styleCatIDs[]=" + category[i];
        }
    }
    return param;
};

const GetParamCondition = (condition: number[]) => {
    if (condition.length === 0 || condition.length >= 4) {
        // 없거나 전체면 파라미터 제거함.
        return "";
    }

    let param = "";
    for (let i = 0; i < condition.length; i++) {
        param += "&conditionIDs[]=" + condition[i].toString();
    }
    return param;
};

const GetParamStr = (items: any, key: string) => {
    let param = "";
    if (items) {
        items.map((i: any) => {
            param += "&" + key + "[]=" + i;
        });
    }
    return param;
};

const GetMetaCoreDPName = (meta: any, id: number) => {
    if (meta) {
        return meta.find((cond: { id: any; coreDPName: any }) => {
            return cond.id === id;
        })?.coreDPName;
    }
    return "";
};

// return array
const GetMetaCoreDPNames = (meta: any, ids: number[]) => {
    if (ids && meta) {
        const names = ids.map((id: number) => {
            return meta.find((cond: { id: any; coreDPName: any }) => {
                return cond.id === id;
            })?.coreDPName;
        });
        return names.toString();
    }
    return "-";
};

const GetMetaDPName = (meta: any, id: number | string) => {
    if (meta) {
        return meta.find((cond: { id: any; engDPName: any }) => {
            return cond.id === id;
        })?.engDPName;
    }
    return "-";
};

const GetMetaID = (meta: any, dpName: string) => {
    if (meta) {
        return meta.find((cond: { id: any; engDPName: any }) => {
            return cond.engDPName === dpName;
        })?.id;
    }
    return "-";
};

const GetEgressReasonName = (id: number) => {
    if (id === eItemStatusIDs.DP_EXCEPTION1 || id === eItemStatusIDs.DP_EXCEPTION2) return "전시 제외";
    else if (id === eItemStatusIDs.DP_FINISHED) return "전시 종료";
    else if (id === eItemStatusIDs.DP_STOPPED) return "전시 중지";
    else if (id === eItemStatusIDs.NOT_FOR_SELL) return "판매 불가";
    else if (id === eItemStatusIDs.SELL_DP_FIN) return "전시 종료";
    return "-";
};

const GetEgressReasonReturning = (id: number) => {
    if (
        [
            eEgressResonType.NOT_FOR_SELL1,
            eEgressResonType.NOT_FOR_SELL2,
            eEgressResonType.NOT_FOR_SELL3,
            eEgressResonType.NOT_FOR_SELL4,
        ].includes(id)
    )
        return "판매 불가";
    else if ([eEgressResonType.DP_STOPPED1, eEgressResonType.DP_STOPPED2].includes(id))
        return "전시 종료";
    return "-";
};

const GetWonValue = (price: number) => {
    if (price > 0) return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); // 받은 값에 3자리수마다 콤마를 추가
    return "-";
};

const Compare = (a: any, b: any, sortType: string) => {
    if (a === "-" || b === "-") {
        if (a === "-" && b !== "-") return sortType === "descending" ? 1 : -1;
        if (a !== "-" && b === "-") return sortType === "descending" ? -1 : 1;
        if (a === "-" && b === "-") return 0;
    }
    const date1 = new Date(a);
    const date2 = new Date(b);

    if (date1 < date2) return sortType === "descending" ? 1 : -1;
    if (date1 > date2) return sortType === "descending" ? -1 : 1;
    return 0;
};

const stringCompare = (a: any, b: any, sortType: string) => {
    if (a < b) return sortType === "descending" ? 1 : -1;
    if (a > b) return sortType === "descending" ? -1 : 1;
    if (a === b) return 0;
};

const CheckedStr = (str: string) => {
    if (!str) return "-";
    if (str.length < 1) return "-";
    return str;
};

// 자릿수 만큼 0으로 채우는 함수
const GetZeroNum = (n: number, cipher: number) => {
    return ("0000" + n).slice(-cipher);
};

const GetFullAddress = (delivery: any) => {
    return (
        delivery?.cityAddress +
        " " +
        delivery?.districtAddress +
        " " +
        delivery?.roadAddress +
        ", " +
        delivery?.detailAddress
    );
};

const OpenDeliveryTracking = (trackingNumber: string) => {
    window.open(
        `http://trace.cjlogistics.com/next/tracking.html?wblNo=${trackingNumber}`,
        "NewWindow",
        "width=1520,height=830,resizable=yes,scrollbars=yes"
    );
};

const OpenItemPreview = (id: number) => {
    window.open(
        process.env.REACT_APP_ITEM_URL + id.toString(),
        "NewWindow",
        "width=474,height=1017,resizable=yes,scrollbars=yes"
    );
};

const CheckRequiredItem = (item: any, name: string, setOrderText: any, setOrderOpen: any) => {
    if (!item[name] || item[name] === "") {
        setOrderText(name + "은(는) 필수 입력 사항입니다.");
        setOrderOpen(true);
        return false;
    }
    return true;
};

// 출력 포맷 : "2022-11-30T00:00:00Z",
const ExcelDateToJSDate = (date: number) => {
    const converted_date = new Date(Math.round((date - 25569) * 864e5));
    const dateStr = String(converted_date).slice(4, 15);
    const dateArr = dateStr.split(" ");
    const month = GetZeroNum("JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(dateArr[0]) / 3 + 1, 2);
    return String(dateArr[2] + "-" + month + "-" + dateArr[1] + "T00:00:00Z+09:00");
};

// 출력 포맷 : "2022-11-30T00:00:00Z",
const ExcelDateToJSTime = (date: number) => {
    const converted_date = new Date(Math.round((date - 25569) * 864e5));
    const dateStr = String(converted_date).slice(4, 24);
    const dateArr = dateStr.split(" ");
    const month = GetZeroNum("JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(dateArr[0]) / 3 + 1, 2);
    return String(dateArr[2] + "-" + month + "-" + dateArr[1] + "T" + dateArr[3] + "Z+09:00");
};

export const GcpPublicUrl = "https://storage.googleapis.com/mineis-charan/";

const GetGcpFilename = (id: number) => {
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    const day = new Date().getDate();

    const nfsPath = "charan-studio/release/nfs/";
    return (
        nfsPath +
        year.toString() +
        "/" +
        GetZeroNum(month, 2).toString() +
        GetZeroNum(day, 2).toString() +
        "/nfs_" +
        id.toString() +
        ".jpeg"
    );
};

export interface IStorageExportHandleProps {
    itemID: number;
    selectedStorageID: string;
}

const validateScannedItem = (
    itemID: number,
    selectedStorageID: string,
    storageExportList: StorageExportListProps
) => {
    return (storageExportList ?? {})[selectedStorageID].find(
        (item: StorageExportItemProps) => item.id === itemID
    )
        ? true
        : false;
};

const scanItem = (
    itemID: number,
    selectedStorageID: string,
    storageExportList: StorageExportListProps,
    setStorageExportList: React.Dispatch<React.SetStateAction<StorageExportListProps>>
) => {
    const specificStorageItemList = [...(storageExportList ?? {})[selectedStorageID]];
    const scannedItem = specificStorageItemList.find((item) => item.id === itemID);
    if (!scannedItem) {
        return;
    }
    setStorageExportList({
        ...storageExportList,
        [selectedStorageID]: [
            {
                ...scannedItem,
                scanned: scannedItem.id === itemID ? !scannedItem.scanned : scannedItem.scanned,
            },
            ...specificStorageItemList.filter((item) => item.id !== itemID),
        ],
    });
};

const getFormattedDateWithDateType = (date: Date) => {
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
};

const swapElements = ({
    list,
    firstIndex,
    secondIndex,
}: {
    list: any[];
    firstIndex: number;
    secondIndex: number;
}) => {
    const swappedList = [...list];
    const elementOfFirstIndex = swappedList[firstIndex];
    swappedList[firstIndex] = swappedList[secondIndex];
    swappedList[secondIndex] = elementOfFirstIndex;
    return swappedList;
};

const API_SUCCESS_RESPONSE = "200000";

const isUrl = (string: string) => {
    const urlPattern = new RegExp(
        "^(https?:\\/\\/)?" +
            "((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|" +
            "((\\d{1,3}\\.){3}\\d{1,3}))" +
            "(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*" +
            "(\\?[;&a-zA-Z\\d%_.~+=-]*)?" +
            "(\\#[-a-zA-Z\\d_]*)?$",
        "i"
    );
    return !!urlPattern.test(string);
};

const logoutWithNavigateHome = () => {
    removeCookie(USER_INFO_COOKIE_KEY, { path: "/" });
    removeCookie(ACCESS_TOKEN_COOKIE_KEY, { path: "/" });
    location.href = "/";
};

export {
    eBagStatusIDs,
    eItemStatusIDs,
    ePageUpdateType,
    UpdateStatus,
    UpdateStatusCount,

    // 검색 파라미터 자동생성
    GetParamStartEnd,
    GetParamID,
    GetParamBrand,
    GetParamCategory,
    GetParamCondition,
    GetParamStr,
    GetParamDate,

    // Functions for talbe ID to String
    GetMetaCoreDPName,
    GetMetaCoreDPNames,
    GetFormattedDate,
    GetMetaDPName,
    GetEgressReasonName,
    GetEgressReasonReturning,
    getFormattedDateWithDateType,

    // Functions for DPName to ID
    GetMetaID,

    // Price to Won
    GetWonValue,

    // Sort
    Compare,
    stringCompare,

    // Etc
    CheckedStr,
    GetZeroNum,
    GetFullAddress,
    OpenDeliveryTracking,
    OpenItemPreview,
    CheckRequiredItem,
    ExcelDateToJSDate,
    ExcelDateToJSTime,
    GetGcpFilename,
    GetFormattedDateOnly,
    validateScannedItem,
    scanItem,
    API_SUCCESS_RESPONSE,
    swapElements,
    isUrl,
    logoutWithNavigateHome,
};
